import React from 'react';
import { withSubtheme } from '../../../../StarberryComponentsMui';
import { FormStoreProvider } from "../../../../services";

// MUI imports
import { Dialog, useMediaQuery } from '@mui/material';

// Components
import Logo from '../../Logo/LogoLogin';
import FeedbackForm from './action/feedback';
import Appointment from './action/appointment';
import  VerifyEmail from './action/verifyEmail';
import  AutoLogin from './action/autologin';

// Action Dialog style
import defaults from '../defaults';

const actionDialog = withSubtheme((props) => {
    const {
        theme,
        className,
        dialogTitleProps,
        dialogContentProps,
        dialogAction,
        logoType,
        type,
        code
    } = props

    const {
        fullScreen,
    } = dialogAction

    const fullScreenBreakpoint = fullScreen ? useMediaQuery(theme.breakpoints.down(fullScreen)) : false;

    if (type === 'autologin') {
        return (
            <AutoLogin
                dialogTitleProps={dialogTitleProps}
                dialogContentProps={dialogContentProps}
                code={code}
            />
        )
    }

    return (
        <>
            <FormStoreProvider>
                <Dialog
                    fullScreen={fullScreenBreakpoint}
                    open={true}
                    aria-labelledby="acionDialog-dialog"
                    classes={{root: className}}
                    BackdropProps={{ classes: {root: "backDrop"} }}
                    maxWidth={`acionDialog-modal`}
                    fullWidth={true}
                    className={`acionDialog-modal`}
                    scroll="body"
                >
                    <Logo type1={logoType || 'logo'} />

                    {type === 'appointment' && (
                        <Appointment
                            dialogTitleProps={dialogTitleProps}
                            dialogContentProps={dialogContentProps}
                            code={code}
                            type={type}
                        />
                    )}

                    {type === 'feedback' && (
                        <FeedbackForm
                            dialogTitleProps={dialogTitleProps}
                            code={code}
                            type={type}
                        />
                    )}
                    {type === 'emailVerify' && (
                        <VerifyEmail
                            dialogTitleProps={dialogTitleProps}
                            code={code}
                            type={type}
                        />
                    )}
                </Dialog>
            </FormStoreProvider>
        </>
    );
}, 'loginSignup', defaults);

export default React.memo(actionDialog);