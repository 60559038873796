import * as React from 'react';
import { get, isEmpty } from 'lodash';
import Actions from '../../../actions';
import Alert from '../../../alert';
import StyledDialogTitle from '../../title';
import { useFormState } from '../../../../../services';
import GlobalForm from "../../../forms"
import { collectFormValues, validateFormFields } from "../../../forms/utils"
import actionMessageFields from "../../../forms/Forms/actionFeeback"
import { trackFeedback } from '../../../helper/eventTracking';
import configs from '../../../actions/action/feedback/defaults';
import CircularProgress from '../../../progress/CircularProgress'
import { Button, Grid } from '@mui/material';

const ActionsDialogFeedback = (props) => {

    return(
        <>
            <Grid classes={{root: "alertContainerPlain"}}>
                <Alert type="alert" classes="actionCardDialogChildren-alert" message={`<h3>Thank you for confirming your email address.Your account is now fully activated and ready to use. You can now enjoy all the features and benefits of our platform.</h3>`} severity={'success'} />
                <Button variant="contained" href="/login">Click here to login</Button>
            </Grid>
        </>
    )
}

export default ActionsDialogFeedback;
