import * as React from 'react';
import { isEmpty } from 'lodash';
import CircularProgress from '../../../progress/CircularProgress'
import { useAutoLoginCheckQuery } from '../../../../../redux/services/profile'
import { doRedirect } from "../../../utils"
import { storeToken, getUser } from "../../../../../services/store/utils"

const ActionsAutoLogin = ({code}) => {

   
    const { data, isError, isLoading, status } = useAutoLoginCheckQuery({code});

    if (!isEmpty(data) && !isLoading && status =="fulfilled") {
        storeToken(data);

        setTimeout(() => {
            let user = getUser()
            if (user?.email)
                doRedirect('/login', true);

        }, 1000);
    }

    if (isEmpty(data) && !isLoading && isError &&  status == "rejected") {
        setTimeout(() => {
            doRedirect('/', true);
        }, 1000);
    }


    return <CircularProgress />
}

export default ActionsAutoLogin;
